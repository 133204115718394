<template>
  <div
    @click="$emit('click')"
    class="`
      flex flex-row border flex-grow-0 flex-shrink-0 border-contrast
      rounded-lg cursor-pointer w-72 mt-4 md:w-80 lg:w-96 bg-backfill h-16
      room-component
    `"
  >
    <div class="flex flex-col items-start justify-between w-2/3 py-2 pl-4">
        <p class="font-bold text-primary">{{name}}</p>
        <p class="text-xs text-content">{{ itemNumString }}</p>
    </div>

    <div
      class="w-1/3 h-full overflow-hidden bg-center bg-cover rounded-r-lg"
      :style="`background-image: url('${image}');`"
    ></div>

  </div>
</template>

<script>
export default {
  name: 'RoomComponent',
  props: {
    name: {
      type: String,
      required: true,
    },
    itemNum: {
      type: Number,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
  },
  computed: {
    itemNumString() {
      if (this.itemNum === 1) return '1 item';
      return `${this.itemNum} items`;
    },
  },
};
</script>
