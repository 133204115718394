<template>
  <modal-container
    @cancel="$emit('cancel')"
  >

    <p class="w-full text-xl font-bold text-center text-primary">{{title}}</p>
    <p class="pt-2 font-bold text-left">{{description}}</p>
    <div class="flex flex-row justify-end w-full pt-8">

      <button-large
        @click="$emit('cancel')"
        color="secondary"
        class="cancel-walkthrough"
      >
        {{textCancel}}
      </button-large>

      <button-large
        v-if="textConfirm.length > 0"
        @click="$emit('confirm')"
        color="green"
        class="ml-5 continue-walkthrough"
      >
        {{textConfirm}}
      </button-large>

    </div>
  </modal-container>
</template>

<script>
import ModalContainer from '@/components/shared/containers/ModalContainer.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'ModalConfirmation',
  components: {
    ButtonLarge,
    ModalContainer,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    textCancel: {
      type: String,
      required: true,
    },
    textConfirm: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>

</style>
