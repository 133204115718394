<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="backNav"
      :show-bottom-nav="true"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      :modal-show="showWalkthroughModal"
      :showRightControls="true"
      backButtonText="Projects"
      :loading="loading"
    >

      <template #modal>
        <walkthrough-modal
          v-if="showWalkthroughModal"
          title="Your First Project!"
          @confirm="incrementWalkthrough"
          @cancel="cancelWalkthrough"
          description="
            Welcome to your first KIV Project! This is the main screen.
            You can add rooms and then items to those room. For example
            you could add a 'Master bedroom' as the room and then
            for an item you could add 'King sized bed'.
            You also have access to amazing KIV checklists for safety
            and other necessary services.
            Let's take a look before we go to the project timeline page.
          "
          textCancel='Finish'
          textConfirm='View Lists'
        />
      </template>

      <template #title>
        {{ project.name }}
      </template>

      <template #right-action-buttons>
        <right-page-action-button
          v-for="rightActionButton in rightActionButtons"
          :key="rightActionButton.title"
          @click="$router.push(rightActionButton.route)"
          :active="false"
          :title="rightActionButton.title"
          :icon="rightActionButton.icon"
          :pulse="pulseRightActionButton(rightActionButton.title)"
        />
      </template>

      <template #content>
        <div class="flex flex-col self-center h-full pt-5 mt-2 mb-5">

          <div class="``
            absolute top-0 w-72 md:w-80 lg:w-96 my-auto flex flex-row justify-center mx-auto
          ">
            <div
              class="`
                flex-grow-0 flex flex-col flex-shrink-0 pt-2 w-72 md:w-80 lg:w-96 bg-background
              `"
            >
              <tab-input
                leftTab="Declutter"
                center-tab="Services"
                rightTab="Safety"
                :selected="selected"
                @update:selected="tabClick"
                :pulse="pulseTab"
              />
              <div
                v-if="project.rooms.length > 0 && selected === 0"
                class="flex flex-row justify-center w-full pb-2 mt-4"
              >
                <search-bar
                  :value.sync="search"
                  class="w-full"
                />
              </div>
            </div>

          </div>

          <div class="h-full">
            <!-- Declutter -->
            <div
              v-if="selected === 0"
              class="flex flex-col flex-grow h-full pt-16 declutter w-72 md:w-80 lg:w-96"
            >
                <room-component
                  v-for="(room, index) in filteredRooms"
                  @click="navToRoom(room.id)"
                  :key="`room-${index}`"
                  :name="room.name"
                  :itemNum="room.num_of_items"
                  :image="room.picture.url || '/img/main-image.jpg'"
                />

                <div
                  v-if="project.rooms.length == 0"
                  class="`
                    flex flex-col justify-end items-center flex-grow
                    px-16 font-bold text-center text-content
                  `"
                >
                  <p>You currently do not have any rooms.</p>
                  <p>Click the button below to create your first!</p>
                </div>

                <div class="flex flex-col justify-end flex-grow flex-shrink-0 pt-4 pb-10">

                  <button-large
                    @click="createRoom"
                    color="primary"
                    class="self-center w-32"
                  >
                    New Room
                  </button-large>
                </div>

            </div>

            <!-- Services -->
            <div
              v-else-if="selected === 1"
              class="flex flex-row flex-wrap mt-7 services w-72 md:w-80 lg:w-96"
            >
              <checklist-component
                v-for="list in serviceLists" :key="list.name"
                @click="navToChecklist(list.id)"
                :name="list.name"
                :complete="totalCompleted(list)"
                :total="list.items.length"
                :pictureUrl="list.picture.url"
              />
            </div>

            <!-- Safety -->
            <div
              v-else-if="selected === 2"
              class="flex flex-row flex-wrap mt-7 services w-72 md:w-80 lg:w-96"
            >
              <checklist-component
                v-for="list in safetyLists" :key="list.name"
                @click="navToChecklist(list.id)"
                :name="list.name"
                :complete="totalCompleted(list)"
                :total="list.items.length"
                :pictureUrl="list.picture.url"
              />
            </div>

            <div class="opacity-0">space</div>
          </div>

        </div>

      </template>

      <template #bottom-nav-left>
        <item-views
          v-if="selected === 0"
          @click="itemList"
          class="cursor-pointer"
          :size='37'
        />
        <heart-icon
          v-else
          @click="wishlist"
          class="cursor-pointer wishlist"
          :size='37'
        />
      </template>
      <template #bottom-nav-center>
        <settings
          @click="settings"
          class="cursor-pointer settings"
          :size='37'
          :class="{'animate-pulse border-2 rounded-lg border-yellow-300':pulseSettings}"
        />
      </template>
      <template #bottom-nav-right>
        <timeline
          @click="timeline"
          class="cursor-pointer timeline"
          :size='37'
          :class="{'animate-pulse border-2 rounded-lg border-yellow-300':pulseTimeline}"
        />
      </template>

    </interior-page-content-container>
</template>

<script>
import HeartIcon from 'vue-material-design-icons/Heart.vue';
import Settings from 'vue-material-design-icons/Cog.vue';
import ItemViews from 'vue-material-design-icons/ViewList.vue';
import Timeline from 'vue-material-design-icons/Timeline.vue';

import { mapProjectFields } from '@/store/mappers';
import { PROJECT_GET, CHECKLISTS_GET, USER_WALKTHROUGH_DISPATCH } from '@/store/actions';
import { PROJECT_PERMISSIONS_CURRENT, USER_WALKTHROUGH_STATE } from '@/store/getters';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import SearchBar from '@/components/shared/inputs/SearchBar.vue';
import TabInput from '@/components/shared/inputs/TabInput.vue';
import RoomComponent from '@/components/projects/RoomComponent.vue';
import ChecklistComponent from '@/components/projects/checklists/ChecklistComponent.vue';
import RightPageActionButton from '@/components/shared/inputs/RightPageActionButton.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';
import WalkthroughModal from '@/components/walkthrough/WalkthroughModal.vue';

export default {
  name: 'Project',
  components: {
    HeartIcon,
    Settings,
    InteriorPageContentContainer,
    SearchBar,
    TabInput,
    RoomComponent,
    ChecklistComponent,
    ItemViews,
    RightPageActionButton,
    ButtonLarge,
    WalkthroughModal,
    Timeline,
  },
  data() {
    return {
      loading: true,
      selected: 1,
      search: '',
      checklists: [],
    };
  },
  computed: {
    ...mapProjectFields(['project', 'projects']),
    projectPermissions() {
      return this.$store.getters[PROJECT_PERMISSIONS_CURRENT];
    },
    showWalkthroughModal() {
      return this.$store.getters[USER_WALKTHROUGH_STATE] === 8;
    },
    pulseTab() {
      const stage = this.$store.getters[USER_WALKTHROUGH_STATE];
      if (stage === 9) return 'center';
      if (stage === 10) return 'right';
      return '';
    },
    pulseSettings() {
      return this.$store.getters[USER_WALKTHROUGH_STATE] === 11;
    },
    pulseTimeline() {
      return this.$store.getters[USER_WALKTHROUGH_STATE] === 10.1;
    },
    filteredRooms() {
      const rooms = this.project.rooms.filter((room) => room.name.toLowerCase().includes(
        this.search.toLowerCase(),
      ));
      return rooms;
    },
    serviceLists() {
      return this.checklists.filter((list) => list.type === 'Service');
    },
    safetyLists() {
      return this.checklists.filter((list) => list.type === 'Safety');
    },
    rightActionButtons() {
      return [
        {
          title: 'Settings',
          icon: 'settings',
          route: {
            name: 'ProjectSettings',
            params: { project_id: this.$route.params.project_id },
          },
        },
        {
          title: 'All Items',
          icon: 'items',
          route: {
            name: 'ProjectItemList',
            params: { project_id: this.$route.params.project_id },
          },
        },
        {
          title: 'All To Do',
          icon: 'heart',
          route: {
            name: 'ProjectWishlist',
            params: { project_id: this.$route.params.project_id },
          },
        },
        {
          title: 'Timeline',
          icon: 'timeline',
          route: {
            name: 'ProjectTimeline',
            params: { project_id: this.$route.params.project_id },
          },
        },
      ];
    },
  },
  watch: {
    $route(newVal) {
      if (newVal.params.tab.toLowerCase() === 'declutter') this.selected = 0;
      else if (newVal.params.tab.toLowerCase() === 'services') this.selected = 1;
      else if (newVal.params.tab.toLowerCase() === 'safety') this.selected = 2;
    },
  },
  created() {
    this.loading = !this.projects[this.$route.params.project_id];
    this.project = this.projects[this.$route.params.project_id] || {};
    this.$store.dispatch(CHECKLISTS_GET, this.$route.params.project_id)
      .then((checklists) => {
        this.checklists = checklists;
        this.checklists.sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
      });

    this.$store.dispatch(PROJECT_GET, this.$route.params.project_id)
      .catch((error) => {
        console.log(error);
        this.$router.push({
          name: 'SpecificPageNotFound',
          params: { page: 'project' },
        });
      })
      .finally(() => { this.loading = false; });

    if (this.$route.params.tab.toLowerCase() === 'declutter') this.selected = 0;
    else if (this.$route.params.tab.toLowerCase() === 'services') this.selected = 1;
    else if (this.$route.params.tab.toLowerCase() === 'safety') this.selected = 2;

    else this.$router.push({ name: 'PageNotFound' });
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters[USER_WALKTHROUGH_STATE] < 100) {
      if (to.name === 'ProjectTimeline') {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 10.2);
      } else if (to.name === 'ProjectSettings') {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH);
      } else if (this.$store.getters[USER_WALKTHROUGH_STATE] !== 0) {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
      }
    }
    next();
  },
  methods: {
    pulseRightActionButton(btnTitle) {
      return (btnTitle === 'Settings' && this.pulseSettings) || (btnTitle === 'Timeline' && this.pulseTimeline);
    },
    incrementWalkthrough(val) {
      this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, val);
    },
    cancelWalkthrough() {
      this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
    },
    backNav() {
      this.$router.push({ name: 'Projects' });
    },
    totalCompleted(list) {
      let total = 0;
      list.items.forEach((item) => { total += item.completed ? 1 : 0; });
      return total;
    },
    tabClick(newVal) {
      this.selected = newVal;

      let newTab = '';
      if (newVal === 0) {
        newTab = 'Declutter';
      }
      if (newVal === 1) {
        newTab = 'Services';
        if (this.$store.getters[USER_WALKTHROUGH_STATE] === 9) this.incrementWalkthrough();
      }
      if (newVal === 2) {
        newTab = 'Safety';
        if (this.$store.getters[USER_WALKTHROUGH_STATE] === 10) this.incrementWalkthrough(10.1);
      }

      this.$router.push({
        name: 'Project',
        params: {
          project_id: this.$route.params.project_id,
          tab: newTab,
        },
      });
    },
    navToRoom(roomId) {
      this.$router.push({
        name: 'Room',
        params: {
          project_id: this.$route.params.project_id,
          room_id: roomId,
        },
      });
    },
    wishlist() {
      this.$router.push({
        name: 'ProjectWishlist',
        params: { project_id: this.$route.params.project_id },
      });
    },
    createRoom() {
      this.$router.push({
        name: 'RoomCreate',
        params: {
          project_id: this.$route.params.project_id,
        },
      });
    },
    settings() {
      if (this.pulseSettings) {
        this.incrementWalkthrough();
      }
      this.$router.push({
        name: 'ProjectSettings',
        params: { project_id: this.$route.params.project_id },
      });
    },
    timeline() {
      this.$router.push({
        name: 'ProjectTimeline',
        params: { project_id: this.$route.params.project_id },
      });
    },
    itemList() {
      this.$router.push({
        name: 'ProjectItemList',
        params: { project_id: this.$route.params.project_id },
      });
    },
    navToChecklist(checklistId) {
      this.$router.push({
        name: 'Checklist',
        params: {
          checklist_id: checklistId,
          project_id: this.$route.params.project_id,
        },
      });
    },
  },
};
</script>

<style>

</style>
