<template>
  <div class="w-1/2">
    <div
      @click="$emit('click')"
      class="`
        flex flex-row border flex-grow-0 flex-shrink-0 border-contrast
        rounded-lg cursor-pointer  bg-backfill
        pb-3 h-32 m-1
        checklist-component
      `"
    >
    <!-- w-72 mt-4 md:w-80 lg:w-96 -->
      <div class="flex flex-col items-center justify-between w-full pt-2 mx-4">
        <p class="w-full mb-1 text-sm text-left text-primary">{{name}}</p>
        <img :src="pictureUrl" class="h-12 max-w-full rounded-md">

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ChecklistComponent',
  components: {
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    complete: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    pictureUrl: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>

</style>
